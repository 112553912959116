@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Helvetica',
    'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:focus {
  outline: none;
}
main {
  background-color: rgb(230, 230, 230);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-none {
  display: none !important;
}
.gridShowLayout {
  width: 100% !important;
  padding: 0 !important;
}
.noSpacer {
  display: none;
}
.modalForm {
  position: relative;
  z-index: 4000;
}
.fixedToolbar {
  position: absolute;
  bottom: 0;
  top: auto;
}

.listview-title {
  font-size: 22px;
}

